@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap");

@font-face {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://cdn.appsflyer.com/kb/devhub/fonts/Radomir%20Tinkov%20-%20Gilroy-Regular.otf) format("opentype");
}

@font-face {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://cdn.appsflyer.com/kb/devhub/fonts/Radomir%20Tinkov%20-%20Gilroy-Regular.otf) format("opentype");
}

@font-face {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://cdn.appsflyer.com/kb/devhub/fonts/Radomir+Tinkov+-+Gilroy-SemiBold.otf) format("opentype");
}

@font-face {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://cdn.appsflyer.com/kb/devhub/fonts/Radomir+Tinkov+-+Gilroy-SemiBold.otf) format("opentype");
}

@font-face {
  font-family: "Font-Awesome";
  src: url("https://cdn.appsflyer.com/kb/iaegen/webfonts/fa-regular-400.woff2") format("woff2");
}

:root {
  font-family: "Gilroy" !important;
}

* {
  font-family: "Gilroy" !important;
  /* line-height: 2; */
}

h1,
h2,
h3 {
  margin-top: 0.5em !important;
  font-family: "Gilroy" !important;
  font-weight: 600 !important;
}

pre code {
  background-color: transparent;
  tab-size: 1 !important;
  overflow-wrap: anywhere;
  font-size: 0.9em !important;
}

code * {
  font-family: "Menlo" !important;
  overflow-wrap: anywhere;
  text-wrap: auto;
  /* font-size: .85em; */
}

code {
  font-family: "Roboto" !important;
  font-weight: 500 !important;
  background-color: #c9e0f7;
  border: 1px solid var(--color-border-default);
  border-radius: 3px;
  margin: 0;
  /* padding: 0.1em 0.25em; */
}

.code-block-container code {
  font-weight: unset;
  background-color: unset;
  border-radius: unset;
  margin: unset;
  padding: unset;
  font-family: "Roboto" !important;
}

.code-block-container span {
  font-family: "Roboto" !important;
  font-weight: 400 !important;
}

body {
  min-width: 400px;
}

iframe#webpack-dev-server-client-overlay {
  display: none !important;
}

.MuiButton-label,
.MuiButton-outlined,
.MuiButton-contained {
  font-weight: 600 !important;
}

.MuiButton-containedPrimary {
  background-color: #036deb !important;
}

.MuiButton-containedPrimary:hover {
  background-color: #0360ce !important;
}

.MuiButton-containedPrimary.Mui-disabled {
  background-color: #e6e9f0 !important;
}

.MuiButton-outlinedPrimary {
  border-color: #036deb !important;
}

.MuiButton-outlinedPrimary.Mui-disabled {
  border-color: #036deb !important;
}

.MuiButton-outlinedPrimary:hover {
  border-color: #e6e9f0 !important;
}

.MuiButton-containedPrimary {
  background-color: #036deb !important;
}

.MuiButton-outlinedSecondary {
  color: #036deb !important;
  border-color: #036deb !important;
}

.MuiButton-outlinedSecondary.Mui-disabled {
  background-color: #e6e9f0 !important;
  border-color: #e6e9f0 !important;
  color: rgba(171, 178, 195, 1) !important;
}

.MuiButton-outlinedSecondary:hover {
  background-color: #e8f1fa !important;
}

.MuiStepIcon-root.MuiStepIcon-active,
.MuiSvgIcon-colorPrimary .Mui-checked {
  color: #036deb !important;
}

.MuiStepIcon-root.MuiStepIcon-completed {
  color: #258750 !important;
}

.MuiAlert-filledSuccess {
  background-color: #258750 !important;
}

.MuiAlert-filledError {
  background-color: #d83a52 !important;
}